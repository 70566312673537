import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Post = ({ post }) => (
  <li key={post.title}>
    <Link to={`/blog/${post.slug}`}>{post.title}</Link>
    <span className="date">{post.publishedDate}</span>
  </li>
)

const RecentPosts = ({ posts }) => {
  return (
    <div className="widget">
      <h6 className="title">Recent Posts</h6>
      <hr />
      <ul className="link-list recent-posts">
        {posts && posts.length ? (
          posts.map(post => <Post key={post.title} post={post} />)
        ) : (
          <p>No Recent Posts</p>
        )}
      </ul>
    </div>
  )
}

RecentPosts.propTypes = {
  posts: PropTypes.array,
  Post: PropTypes.node,
}

Post.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  createdDate: PropTypes.string,
}

export default RecentPosts
